//------------------------------------------------------
//   Generic Component to use the login users to Firebase
//   https://firebase.google.com/docs/auth/web/microsoft-oauth#popup-mode
//
//   How to use this component: 
//
//    Import this component
//    <Login 
//      autoLogin={true}  This Optional prop automatically triggers a signin when the Login component mounts
//    >
//    </Login>
//
//------------------------------------------------------

import React, {useContext} from 'react'
import { getAuth, signInWithRedirect } from "firebase/auth";
import {GetFireBaseProvider, SetAppStatus, SetAppErrors} from './GlobalContexts';


export default function Login(props) {

  //------------------------------------------------------
  //  Props
  //------------------------------------------------------

    const autoLogin = props.autoLogin

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#handle_the_sign-in_flow_with_the_firebase_sdk
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Firebase
    const Provider = useContext(GetFireBaseProvider);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  // Define the function used to sign in
  // The below code will detect errors and present them to the user
  // https://firebase.google.com/docs/auth/web/microsoft-oauth#web-version-9_4
  //------------------------------------------------------

    function SignIn(){

    // When the component mounts, useContext takes time to initialise. 
    // This if statement allows the useContext time to load.
      if (Provider === undefined) return;

      signInWithRedirect(auth, Provider).catch((error) =>{

        setAppStatus('failed');
        setAppErrors(error.message);

      });

    }
    
  //------------------------------------------------------
  //  Return
  //------------------------------------------------------
  
  // If the user already has a Lendlease account, this directs them to an auto login.
    if (autoLogin){

      SignIn();
      return null;

    }

  // This gives the user the option to sign in. 
    else {
      return (
        <button className="LoginPage-Body-SignIn-Button" onClick={SignIn}>Sign in</button>
      )
    }
}