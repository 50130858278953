//==========================================================================
//   Component: Write Usage Metric
//==========================================================================

//   Purpose: Component that gets the current route and writes metric to Firestore collection

//   Example: 
//   <WriteUsageMetric></WriteUsageMetric>

//------------------------------------------------------

//Libraries
// eslint-disable-next-line
import React, { useEffect, useContext } from 'react';
import {useLocation} from 'react-router';

//Contexts
import { GetFireBaseUser } from './GlobalContexts';

//Functions
import WriteDocument from './WriteDocument';


function WriteUsageMetric(){

    //------------------------------------------------------
    //  useLocation > Get current route/pathname
    //------------------------------------------------------

        const routerUseLocation = useLocation();
        var currentPath = (routerUseLocation.pathname).toString()

    //------------------------------------------------------
    //  useContext > Get Firebase UID
    //------------------------------------------------------

        const getFireBaseUser = useContext(GetFireBaseUser);


    //------------------------------------------------------
    //  Functions 
    //------------------------------------------------------    

    // Function to format date for Big Query Table -> 'YYYY-MM-DD HH:MM:SS' 
    function getDateInFormat() {

        // Add 0 to the start of string if not the required length //
        function toString(number, padLength) {
            return number.toString().padStart(padLength, '0');
        }
    
        const date = new Date();
    
        // YYYY-MM-DD HH:MM:SS -> string //
        const dateTimeNow =
                     toString( date.getFullYear(),     4 )
            + '-'  + toString( date.getMonth() + 1,    2 )
            + '-'  + toString( date.getDate(),         2 )
            + ' ' + toString( date.getHours(),        2 )
            + ':'  + toString( date.getMinutes(),      2 )
            + ':'  + toString( date.getSeconds(),      2 );
    
        return dateTimeNow;
    }

    // Get Service from the url path // 
    function getService () {

        //Services that we require // 
        const arrayOfServices = ["onboarding", "mfareset"]
        
        // Store the service name // 
        let serviceName;
        
        // Convert routes '/home' & '/' to onboarding service // 
        currentPath === '/home' || currentPath === '/' ? serviceName = 'onboarding' : serviceName = arrayOfServices.filter(service => currentPath.includes(service))[0]

        return serviceName
    }

    
    //------------------------------------------------------
    //  Define useEffects 
    //------------------------------------------------------

        useEffect(() => {

            //Conditions
            if (getFireBaseUser === undefined) return;
            if(getFireBaseUser?.uid === undefined || getService() === undefined) return;
            
            //Create unique ID using email address and current timestamp
            const id = `${getFireBaseUser?.uid}-${Date.now()}`


            //Prepare document > Write to Firestore
            const usageDocument = {
                "id": id,
                "route": currentPath,
                "servicename": getService(),
                "datetime": getDateInFormat(),
                "uid": getFireBaseUser?.uid
            }
            
            WriteDocument('onboardingusage', id, usageDocument, false)
            .catch((error) =>{
                console.log(`Unable to write usage metric for ${currentPath}. Error: ${error}`)
            })

        // eslint-disable-next-line
        }, [currentPath])


    //------------------------------------------------------
    //  Return null (Function runs in App.js > no HTML) 
    //------------------------------------------------------

        return null
  
}

//------------------------------------------------------

export default WriteUsageMetric;