//------------------------------------------------------
//  Handler for all login events
//  Designed to handle the login process, handle failures and if success give a user an getAccessToken
//  The 'LoginHandler' is built to handle all 'getAppStatus' states
//------------------------------------------------------


//Libraries
import React, { useContext } from 'react';
import App from '../App';
import { BrowserRouter } from 'react-router-dom';
import {GetAppStatus, GetAppErrors} from './GlobalContexts';

//Components
import Login from './Login';
import Logout from './Logout';
import { Footer, Header } from '../Components/Generic/GenericComponents';

//Images
import LoadingIcon from '../Components/Images/Loading_Ripple.svg';
import SessionExpired from '../Components/Images/Session_Expired.svg'
import ErrorGraphic from '../Components/Images/Error_Graphic.svg'


export default function LoginHandler() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getAppStatus = useContext(GetAppStatus);
    const getAppErrors = useContext(GetAppErrors);



  //------------------------------------------------------
  //  Authorised Users
  //------------------------------------------------------

    if (getAppStatus === 'authenticated'){

      //Return the App
      return (
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      )
    }

  //------------------------------------------------------
  //  Expired
  //------------------------------------------------------

    else if (getAppStatus === 'expired'){
        
      return (
        <>

          {/* Header */}
          <Header />

          <div className="Feedback-Body-Container Feedback-Grid">

              {/* Image */}
              <div className="Grid-Left">
                <img className='Grid-Image' alt="Error Graphic" src={SessionExpired} />
              </div>

            {/* Text */}
            <div className="Grid-Right">
                  <h1>Sorry, we logged you out!</h1>
                  <p style={{minWidth: '700px'}}>The one hour session limit was reached. Click the button below to return to the sign in page or close this window. .</p>
                
                  {/* Button */}
                  <Logout title='Return to sign in page'></Logout>
                
              </div> 
          </div>

          {/* Footer */}
          <Footer />

        </>
      )
    }

  //------------------------------------------------------
  //  Pending
  //------------------------------------------------------

    else if (getAppStatus === 'pending'){

      return (
        <>

          {/* Header */}
          <Header />

          <div className='Feedback-Body-Container Feedback-Centre' style={{minHeight: '100vh'}}>
                    
            {/* Pending image */}
            <img style={{height: '40%'}} alt="loading-circle-icon" src={LoadingIcon}></img>
            <Logout failedAppStatus='failed' title='Cancel'></Logout>
          
          </div>

          {/* Footer */}
          <Footer />
        </>
      )
    }

  //------------------------------------------------------
  //  Failed
  //------------------------------------------------------

    else if (getAppStatus === 'failed'){

      //------------------------------------------------------
      //  Handles each known error message, with a generic catch all response!
      //------------------------------------------------------

        function HandleErrorMessage(message){

          //General catch, to ensure something exists
          if (message === undefined) return;

          //Allow third-party cookies
          if (message.includes('web-storage-unsupported')){
            return(
              <div>
                Third party cookies are disabled. Enable <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop'>third-party cookies</a> in your browser and try again.
              </div>
            )
          }

          //Failed to verify the users credenitals, user must close tab and sign in again
          if (message.includes('auth/invalid-credential')){
            return(
              <div>
                Failed verify your credentials. Close the browser and try again.
              </div>
            )
          }

          //Failed to verify the users credenitals, user must close tab and sign in again
          if (message.includes('auth/popup-closed-by-user')){
            return(
              <div>
                You closed the sign in pop up. Click 'return to the sign in page' and try again.
              </div>
            )
          }

          //User is blocking popups
          if (message.includes('auth/popup-blocked')){
            return(
              <div>
                Browser is blocking pop ups. Allow <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop'>pop ups</a> and try again.
              </div>
            )
          }

          //There is an existing Firebase account with this users UPN 
          if (message.includes('auth/account-exists-with-different-credential')){
            return(
              <div>
                Found an existing account with the same email address. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
              </div>
            )
          }

          //General catch all
          else{
            return(
              <div>
                Unknown issue has occured. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
              </div>
            )
          }
        }

      //------------------------------------------------------
      //  Show 'Oops, something went wrong!' page 
      //------------------------------------------------------

        return (
          <>

            {/* Header */}
            <Header />

            <div className="Feedback-Body-Container Feedback-Grid" >

              {/* Image */}
              <div className="Grid-Left">
                <img className='Grid-Image' alt="Error Graphic" src={ErrorGraphic} />
              </div>

              {/* Text */}
              <div className="Grid-Right">
                    <h1>Oops! Something went wrong</h1>
                    <p> {HandleErrorMessage(getAppErrors)}</p>
                    <p><strong>Message: </strong>{getAppErrors}</p>
                    
                    {/* Button */}
                    <Logout title='Return to sign in page'></Logout>
                  
              </div> 

            </div>

            {/* Footer */}
            <Footer />
          
          </>
        )
    }

  //------------------------------------------------------
  //  Unauthenticated Users Land Here (Forced Login)
  //------------------------------------------------------
    
    else if (getAppStatus === 'unauthenticated'){
      return (
        <Login autoLogin={true}></Login>
      )
    }

  //------------------------------------------------------

}