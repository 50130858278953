//--------------------------------------------------------
// Generic function to call the React-Backend
//  IMPORTANT READ THIS: This can take a few seconds when the app first loads!
//  We added a deplay in login handler to make sure an accessToken is in 'sessionStorage'
//--------------------------------------------------------

async function ReactBackend(endPoint, requestBody, retryCount){

    //------------------------------------------------------
    //  Function Variables
    //------------------------------------------------------
  
      if (retryCount === undefined) {
        retryCount = 1;
      }
  
    //------------------------------------------------------
    //  Get an access token from the browsers 'sessionstorage'
    //------------------------------------------------------
  
      //AGAIN this takes a few seconds when the app loads
      const accessToken = sessionStorage.getItem('accessToken');

    //------------------------------------------------------
    //  Call API
    //------------------------------------------------------
  
      const callApiPromise = new Promise((resolve, reject) => {
  
          //Combine Header, POST & jsonBody
          let options;
          try {
  
              options = {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'x-api-key': accessToken,
                  },
                  body: JSON.stringify(requestBody)
              };
  
          } catch (error) {
              
              reject(error.message);
  
          }
  
          //Call the API
          fetch(`${process.env.REACT_APP_CC_BACKEND_URL}/${endPoint}`, options).then((response) =>{
  
              response.json().then((results) =>{
  
                  resolve(results);
  
              }).catch((error) =>{
  
                  reject(error.message);
  
              });
  
          }).catch((error) =>{
  
              reject(error.message);
  
          });
  
      });
  
      
    //------------------------------------------------------
    //  Handle response
    //------------------------------------------------------
  
      return callApiPromise.then((response) =>{
  
          if(response?.status === "success"){
  
              return response;
  
          }
          else if (response?.status === "error-invalid"){
  
              return response;
  
          }
          else if (response?.status === "error-fatal"){
  
              return response;
  
          }
          else if (response?.status === "error-other"){
  
              return response;
  
          }   
          else if (response?.status === "error-timeout"){
  
              return response;
  
          }  
          else{
              return {
                  "status": "error-fatal",
                  "responseBody": {
                      "message": `Unknown error - ${response}. Please contact the Lendlease Cloud team.`,
                      "transactionId": "CSError-500"
                  }
              }
          }
      }).catch((error) =>{
  
          //Retry job if retry attempts are less than 3
          if (retryCount < 3){
  
              retryCount = retryCount + 1;
              return ReactBackend(endPoint, requestBody, retryCount);
  
          } else {
  
              return {
                  "status": "error-fatal",
                  "responseBody": {
                      "message": `Unknown error - ${error.message}. Please contact the Lendlease Cloud team.`,
                      "transactionId": "CSError-500"
                  }
              }
  
          }
  
      });
  
  }
  
  export default ReactBackend;
  
  //--------------------------------------------------------