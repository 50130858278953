// Libraries
import React from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Button } from '../Components/Generic/GenericComponents'

// Images
import NotFoundGraphic from '../Components/Images/404_Graphic.svg'



export default function NotFound() {

  const navigate = useNavigate();

  return (
    <div className="Feedback-Body-Container Feedback-Grid">

        {/* Image */}
        <div className="Grid-Left">
          <img className='Grid-Image' alt="Access-Denied" src={NotFoundGraphic} />
        </div>

        {/* Text */}
        <div className="Grid-Right">
            <h1>Page Not Found</h1>
            <p>Error 404. The page you were looking for could not be found</p>
            
            {/* Button */}
            <Button
            className="Button-Primary"
            text="Go back to home"
            clickHandler={() => navigate('/')}
            />
            
        </div> 
    </div>
  )
}
