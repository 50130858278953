//===============================================================================================
//  Component: Tabs
//===============================================================================================

//  Purpose: This component combines the <TabItem/> & <TabContent/> components to create a complete 
//  Tab View. It pairs the TabItem & TabContent that share the same id. Once a TabItem is clicked
//  it becomes active and the matching content is then displayed.

 

//  Example:
//
//      <ul>
//        <TabItem title="Rick" id="rick" activeTab={activeTab} setActiveTab={setActiveTab}
//        <TabItem title="Morty" id="morty" activeTab={activeTab} setActiveTab={setActiveTab}
//      </ul>
//
//      <div>     !---- To Apply Custom Styling Styling ----!
//
//        <TabContent id="example activeTab={activeTab}>
//            --- CONTENT HERE -> Any HTML & CSS ---
//        </TabContent>
//        <TabContent id="overview" activeTab={activeTab}>
//            --- CONTENT HERE -> Any HTML & CSS ---
//        </TabContent> 
//
//      </div>  

//==============================================================================================



// Libraries
import React, { useState } from "react";

// Components 
import { TabItem, TabContent } from "./TabItem";
import { Button } from "../Generic/GenericComponents";
import Carousel from "../Carousel/Carousel";

//Images
import CommunityGraphic from '../Images/Community_Graphic.svg'
import Security from '../Images/Security_Onboarding.png'

// CSS
import './Tabs.css'

 
export default function Tabs() {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Holds the current active tab -> defaults to overview //
  const [activeTab, setActiveTab] = useState("overview");


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------
 
  return (
    <>

      {/*                       */}
      {/* ----- Tab Items ----- */}
      {/*                       */}

      {/* Tab Navigation Links */}

      <ul className="Tab-Items">
        <TabItem title="Overview" id="overview" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabItem title="Community" id="community" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabItem title="Security" id="security" activeTab={activeTab} setActiveTab={setActiveTab}/>
      </ul>


      {/*                         */}
      {/* ----- Tab Content ----- */}
      {/*                         */}

      {/* Content Container */}
      <div className="Tab-Content">

        {/* Overview Content -> Carousel */}

        <TabContent id="overview" activeTab={activeTab}>
            <Carousel />
        </TabContent>

        {/* Community Content */}

        <TabContent id="community" activeTab={activeTab}>
          <div className="Content-Container">

              {/* Content -> Left Side */}

              <div className="Content-Left">
                <img alt='Community Graphic' src={CommunityGraphic} />
              </div>

            {/* Content -> Right Side */}

              <div className="Content-Right">
                <p>Join the Cloud Guild to engage with the cloud community at Lendlease.</p> 
                <p>Get the latest GCP and Lendlease Cloud updates, and access training material and events. </p>

                {/* Button */}
                <Button 
                className="Button-Primary"
                text="Visit Cloud Guild"
                clickHandler={() => window.location.href ='https://teams.microsoft.com/l/team/19%3aa5ffad10b76148f88d476d14992105b1%40thread.skype/conversations?groupId=126066b3-d6fb-43ef-a2df-a454b0ab6310&tenantId=bc0c325b-6efc-4ca8-9e46-11b50fe2aab5'}
                />

              </div>
          </div>
        </TabContent>


        {/* Security Content */}

        <TabContent id ="security" activeTab={activeTab}>
          <div className="Content-Container">

            {/* Content -> Left Side */}

              <div className="Content-Left">
                <img alt='Security Graphic' src={Security}/>
              </div>

              {/* Content -> Right Side */}
              <div className="Content-Right">

                <p>The way security is managed and governed in the cloud differs from on-prem and requires a shared responsibility model. Visit the <a href="https://lendlease.sharepoint.com/sites/Microsite-Digital/SitePages/Google-Cloud-Platform-(GCP).aspx">Security Microsite</a> to understand your security responsibilities in the cloud. </p> 

                <p>This includes ensuring you have completed a security checklist when setting up a new cloud project and when retiring your project from the cloud platform.</p>

                <p>If you have any questions, contact the security team at <a href="mailto:Cyber-Security@lendlease.com">Cyber-Security@lendlease.com</a></p>

              </div>
          </div>

        </TabContent>
        
      </div>
    </>
  );
};
 
