//------------------------------------------------------
//  Initialize React
//------------------------------------------------------

import React from 'react';
import ReactDOM from 'react-dom/client';

//------------------------------------------------------
//  Initialize useContexts & styling
//------------------------------------------------------

import GlobalContexts from './Library/GlobalContexts'
import './index.css';

//------------------------------------------------------
//  Initialize Firebase & Auth
//  We are using MS App Reg, see the readme for more information
//------------------------------------------------------

import { initializeApp } from 'firebase/app';
import {firebaseConfig} from './Library/FirebaseConfig';
import AuthProvider from './Library/AuthProvider';
import LoginHandler from './Library/LoginHandler';

//Initialize Firebase App
initializeApp({...firebaseConfig});

//------------------------------------------------------
//  Initialize global variables inside 'sessionStorage'
//  Checks for the variable in sessionStorage
//  If it doesn't exist create it!
//------------------------------------------------------

//accessToken
const accessToken = sessionStorage.getItem('accessToken');
if (accessToken === null){

  sessionStorage.setItem('accessToken', '');

}

//accessTokenAuthTime
const accessTokenAuthTime = sessionStorage.getItem('accessTokenAuthTime');
if (accessTokenAuthTime === null){

  sessionStorage.setItem('accessTokenAuthTime', '');

}

//getAppStatus
const getAppStatus = sessionStorage.getItem('getAppStatus');
if (getAppStatus === null){

  sessionStorage.setItem('getAppStatus', '');

}

//------------------------------------------------------
//  Return the App
//------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GlobalContexts>
    <AuthProvider>
      <LoginHandler></LoginHandler>
    </AuthProvider>
  </GlobalContexts>
);

//------------------------------------------------------