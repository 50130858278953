//================================================================
//  Component: Carousel
//================================================================

//  Purpose: This component iterates over an array of objects with 
//  an image and description and displays the image and accompanying  
//  description based upon the index postion.

//  Properties:
//    Currently none, however can be modified to take an array of 
//    objects as a prop
 

//  Example:
//    <Carousel
//    ></Carousel>    

//================================================================


// Libraries
import React, { useState } from 'react'

// Components
import { carouselData } from './CarouselData'

// CSS
import './Carousel.css'


export default function Carousel() {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Holds the active carousel data
    const [active, setActive] = useState(0)

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
        <div className='Carousel-Container'>
            <p>The Lendlease Cloud Portal is the start of your Google Cloud journey.</p>
            
            {/* Carousel Image & text description */}
            
            {carouselData.map((slide, index) => {
                return (
                    <>
                        {index === active && <img className='Carousel-Img' src={slide.image} key={slide.id} alt={slide.description} />}
                        {index === active && <p>{slide.description}</p>}
                    
                    </>
                )
                
            })}


            {/* Dots */}
            <div className="Dot-Container">

                {carouselData.map((slide, index) => {
                    return (
                        <span
                            key={index}
                            className={carouselData[active].id === slide.id ? "Dot Dot-Active" : "Dot"} 
                            onClick={() => setActive(carouselData.indexOf(slide))}
                        />
                    )
                })}

            </div>
        
        </div>
    );

  //------------------------------------------------------
}
