// Libraries
import React, { useEffect, useState, useContext } from "react";

// Contexts
import { SetUser, GetUser } from "../Library/GlobalContexts";

// Functions
import ReactBackend from "../Library/reactBackend";

// Components
import PageResponse from "../Components/PageResponse/pageresponse"
import { Hero, Information, Button } from "../Components/Generic/GenericComponents";
import Tabs from "../Components/Tabs/Tabs";

//Images
import Mail from '../Components/Images/Mail_Graphic.svg';


export default function Onboarding() {

  //------------------------------------------------------
  //  contexts
  //------------------------------------------------------

    const setUser = useContext(SetUser);
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to change between > 'pending', 'success', 'error-invalid', 'error-fatal', 'error-timeout'
    const [pageStatus, setPageStatus] = useState("onload");

    //Used to change between > 'view', 'create' 'modify'
    const [requestType, setRequestType] = useState();

    //Used to route custom messages to pageresponse form
    const [customMessage, setCustomMessage] = useState();


  //------------------------------------------------------
  //  Define Functions
  //------------------------------------------------------

    //Used to call the API & save the state
    async function CallAPI() {

      //API call to react backend
      const requestBody = {
      };

      const response = await ReactBackend("getProfile", requestBody);

      //Process responseBody > get user Profile
      //Set pageStatus to 'success'
      if (response.status === "success") {

        //Dispatch to global store
        setUser({
          "emailaddress": response.responseBody.message.emailaddress,
          "givenname": response.responseBody.message.givenname,
          "surname": response.responseBody.message.surname,
          "passportmember": response.responseBody.message.passportmember
        });

      }


      //Set status to 'error-invalid'
      else if (response.status === "error-invalid") {
        setPageStatus("error-invalid");
        setCustomMessage(response.responseBody.message);
      }

      //Set status to 'error-fatal'
      else if (response.status === "error-fatal") {
        setPageStatus("error-fatal");
        setCustomMessage(response?.requestBody?.message);
      }

      //Set status to 'error-timeout'
      else if (response.status === "error-timeout") {
        setPageStatus("error-timeout");
        setCustomMessage(response?.requestBody?.message);
      }
    };


  //------------------------------------------------------
  //  Define Functions > Send Form Inputs to Backend
  //------------------------------------------------------

    async function CallInviteAPI(){

      const requestBody = {
        givenName: getUser.givenname,
        surname: getUser.surname
      };

      //Call React Backend - Submit Form Response
      const response = await ReactBackend("inviteToPassport", requestBody);
      
      //Switch on status
      if (response.status === "success") {
        
        //Set Request Type to 'create'
        setRequestType("create");

        // Set Page Status to 'success'
        setPageStatus("success")
        
      } else if (response.status === "error-invalid") {
        
        //Set status to 'error-invalid'
        setPageStatus("error-invalid");

      } else if (response.status === "error-fatal") {
        
        //Set status to 'error-fatal'
        setPageStatus("error-fatal");

      }
      else {
        
        //Set status to 'error-timeout'
        setPageStatus("error-timeout");
      }
    };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Onload Render
    useEffect(() => {

      //Get the users profile
      if (pageStatus === "onload") {

        CallAPI();

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageStatus]);

    //Trigger passport invite if required
    useEffect(() => {

      if (getUser === undefined) return;

      //Update the requestType based off the user's profile status

      //CLUD-437: Checks to see if user is both a member of Passport
      //AND in the NEB-PTL-Portal group. If not a member of
      //both they need inviting to be able to access CS Portal

      if (getUser.passportmember === "true"){

        setRequestType('view');
        setPageStatus("success");

      } else {

        // Set pageStatus to 'pending'
        setPageStatus("pending");
        window.scrollTo(0, 0);

        // Call the API
        CallInviteAPI();

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  return(
    <PageResponse
      setPageStatus={setPageStatus}
      pageStatus={pageStatus}
      requestType={requestType}
      customMessage={customMessage}
      pageView={

        // View -> User is already Passport Member //
        <>
        
          {/* Hero Section */}
          <Hero
            subHeader="You already have a cloud passport to the Google Cloud Platform"
            description="Let's get to your cloud destination"
            controls={
              <Button
              className="Button-Primary"
              text="Sign in"
              clickHandler={() => window.location.href =`${process.env.REACT_APP_CS_CUSTOMERPORTAL_URL}`}
              />
            }
          />

          {/* Information Section */}
          <Information/>

          {/* Tab Section -> Controls Overview, Community & Security visibility */}
          <Tabs/>

        </>

      }

      // View -> Passport sign up was successful //
      pageCreate={
        <div className="Feedback-Body-Container Feedback-Grid">
        
            <div className="Grid-Left">
              <h1>Next Steps</h1>
              <p>Please check your email for instructions on the next steps to complete your sign up process.</p>

                  {/* Next Steps Container  */}
                  <div className="Steps-Container"><span className="Blue-Dot">1</span>Check inbox for confirmation email</div>
                  <div className="Steps-Container"><span className="Blue-Dot">2</span> Set up multi-factor authentication</div>
                  <div className="Steps-Container"><span className="Blue-Dot">3</span> Go to the Lendlease Cloud portal</div>

            </div>

            {/* Image */}
            <div className="Grid-Right">
              <img className="Grid-Image" alt="Mail-Box" src={Mail} />
            </div>     

        </div>
      }
    >
    </PageResponse>
  )

  //------------------------------------------------------
};
