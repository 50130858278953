//Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Button } from '../Generic/GenericComponents';

//Images 
import LoadingIcon from '../Images/Loading_Ripple.svg'
import ErrorGraphic from '../Images/Error_Graphic.svg'

function PageResponse(props){

  //------------------------------------------------------
  //  How to use this component
  //-----------------------------------------------------

  // <PageResponse
  //   setPageStatus={setPageStatus}
  //   pageStatus={pageStatus}
  //   requestType={requestType}
  //   pageView={[
  //   <PageViewHTML></PageViewHTML>
  //    GOOD LUCK!
  //   ]}
  //   pageCreate={
  //     <CreateModifyDeleteProject
  //       accessDenied={createAccessDenied}
  //       requestType="create"
  //       setRequestType={setRequestType}
  //     >
  //     </CreateModifyDeleteProject>
  //   }
  //   pageModify={
  //     <CreateModifyDeleteProject
  //     accessDenied={createAccessDenied}
  //     requestType="create"
  //     setRequestType={setRequestType}
  //   >
  //   </CreateModifyDeleteProject>
  //   }
  //   pageCancel={
  //     <CreateModifyDeleteProject
  //     accessDenied={createAccessDenied}
  //     requestType="create"
  //     setRequestType={setRequestType}
  //   >
  //   </CreateModifyDeleteProject>
  //   }
  // >
  // </PageResponse>

  //------------------------------------------------------
  //  Extract props 
  //------------------------------------------------------

    const pageStatus = props.pageStatus
    const setPageStatus = props.setPageStatus
    const requestType = props.requestType
    const pageView = props.pageView
    const pageCreate = props.pageCreate
    const pageModify = props.pageModify
    const pageCancel = props.pageCancel
    const customMessage  = props.customMessage


    const navigate = useNavigate();
    
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  pageStatus === "onload" or "pending"
    //------------------------------------------------------
    
    if(pageStatus === 'onload' || pageStatus === 'pending'){
      return (
        <div className='Feedback-Body-Container Feedback-Centre'>
          
          {/* Pending image */}
          <img style={{height: '40%'}} alt="loading-circle-icon" src={LoadingIcon}></img>
          
          {/* Title */}
          <p>Please wait while we process your request.</p>

          {/* Button */}
           
          <Button
          className="Button-Primary"
          text="Cancel"
          clickHandler={() => setPageStatus("error-fatal")}
          />

        </div>
      );
    }

    //------------------------------------------------------
    //  pageStatus === "success"
    //------------------------------------------------------

    else if(pageStatus === 'success'){
      if(requestType === 'view'){
        return (
          <div>
            {pageView}
          </div>
        )
      }
      else if(requestType === 'create'){
        return (
          <div>
            {pageCreate}
          </div>
        )
      }
      else if(requestType === 'modify'){
        return (
          <div>
            {pageModify}
          </div>
        )
      }
      else if(requestType === 'cancel'){
        return (
          <div>
            {pageCancel}
          </div>
        )
      }
    }

    //------------------------------------------------------
    //  pageStatus === "error-invalid"
    //------------------------------------------------------

    else if(pageStatus === 'error-invalid'){

      return (

        <div className="Feedback-Body-Container Feedback-Grid">

          {/* Image */}
          <div className="Grid-Left">
            <img className='Grid-Image' alt="Error Graphic" src={ErrorGraphic} />
          </div>

            {/* Text */}
            <div className="Grid-Right">
              <div className="Text-Container">
                <h1>Oops! Something went wrong</h1>
                <p>An error occurred while we processed your request. If the error persists, please log a ticket through Service Central here and include the error message below.</p>
                <p><strong>Message: </strong>{customMessage ? customMessage : "Failed to load the page - Invalid Request"}</p>
                
                {/* Button */}
                <Button
                className="Button-Primary"
                text="Try Again"
                clickHandler={() => setPageStatus("onload")}
                />
              </div>
              
            </div> 
        </div>
      );
    }

    //------------------------------------------------------
    //  pageStatus === "error-fatal"
    //------------------------------------------------------

    else if(pageStatus === 'error-fatal'){
        return (

          <div className="Feedback-Body-Container Feedback-Grid">

            {/* Image */}
            <div className="Grid-Left">
              <img className='Grid-Image' alt="Error Graphic" src={ErrorGraphic} />
            </div>

            {/* Text */}
            <div className="Grid-Right">
              <div className="Text-Container">
                <h1>Oops! Something went wrong</h1>
                <p>An error occurred while we processed your request. If the error persists, please log a ticket through Service Central here and include the error message below.</p>
                <p><strong>Message: </strong>{customMessage ? customMessage : "Failed to load the page - Fatal Error"}</p>
                
                {/* Button */}
                <Button
                className="Button-Primary"
                text="Try Again"
                clickHandler={() => setPageStatus("onload")}
                />
              </div>
            </div> 
          </div>
        );
    }


    //------------------------------------------------------
    //  pageStatus === "error-timeout"
    //------------------------------------------------------

    else if(pageStatus === 'error-timeout'){
      return (
        <div className="Feedback-Body-Container Feedback-Grid">


          {/* Image */}
          <div className="Grid-Left">
            <img className='Grid-Image' alt="Error Graphic" src={ErrorGraphic} />
          </div>

            {/* Text */}
          <div className="Grid-Right">
            <div className="Text-Container">
              <h1>Oops! Something went wrong</h1>
              <p style={{minWidth: '700px'}}>An error occurred while we processed your request. If the error persists, please log a ticket through Service Central here and include the error message below.</p>
              <p><strong>Message: </strong>CSError-500</p>
              
              {/* Button */}
              <Button
              className="Button-Primary"
              text="Try Again"
              clickHandler={() => setPageStatus("onload")}
              />
             </div> 
          </div> 
        </div>
      );
    }

  //------------------------------------------------------
}

export default PageResponse;