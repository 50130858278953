import ProjectsImage from '../Images/Projects_Image.png'
import NetworkImage from '../Images/Network_Image.png'
import InsightsImage from '../Images/Insights_Image.png'

// Array of Objects -> Carousel Image and Description //
export const carouselData = [
        {
            "id": 1,
            "image": ProjectsImage,
            "description": "Create and manage your Google Cloud projects",
        },
        {
            "id": 2,
            "image": NetworkImage,
            "description": "Connect to the Lendlease network and self-serve firewall rules, DNS zones and certificates",
        },
        {
            "id": 3,
            "image": InsightsImage,
            "description": "View security, financial and sustainability insights",
        },
]