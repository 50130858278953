//Libraries
import React, { useContext, useEffect, useState } from "react";

//Contexts
import { SetUser } from "../Library/GlobalContexts";

//Functions
import ReactBackend from "../Library/reactBackend";

//Components
import PageResponse from "../Components/PageResponse/pageresponse";
import { Button } from "../Components/Generic/GenericComponents";

//Images
import Unauthenticated from '../Components/Images/Unauthenticated_Graphic.svg';
import MFAAuth from '../Components/Images/Authentication_Graphic.svg';
import ProgrammerGraphic from '../Components/Images/Programmer_Graphic.svg';


function MFAReset() {

  //------------------------------------------------------
  //  contexts
  //------------------------------------------------------

    const setUser = useContext(SetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to change between > 'pending', 'onload', 'success', 'error-invalid', 'error-fatal', 'error-timeout'
    const [pageStatus, setPageStatus] = useState("onload");

    //Used to change between > 'view', 'create', 'modify', 'cancel'
    const [requestType, setRequestType] = useState();

    //Used to route custom messages to pageresponse form
    const [customMessage, setCustomMessage] = useState();


  //------------------------------------------------------
  //  Define Functions
  //------------------------------------------------------

    //Used to call the API & save the state
    async function CallAPI() {

      //API call to react backend
      const requestBody = {
      };

      let response = await ReactBackend("getProfile", requestBody);

      //Process responseBody > get user Profile
      //Set pageStatus to 'success'
      if (response.status === "success") {

        //Dispatch to global Store
        setUser({
          "emailaddress": response.responseBody.message.emailaddress,
          "givenname": response.responseBody.message.givenname,
          "surname": response.responseBody.message.surname,
          "passportmember": response.responseBody.message.passportmember
        });

        //Update the requestType based off the API response

        //CLUD-437: Checks to see if they are a member of Passport only
        //regardless of whether they are in the NEB-PTL-Portal group,
        //as it'll allow Podium users who do not have CS Portal access to reset MFA
        
        if (response.responseBody.message.passportmember === "true"){

          setRequestType('view');

        }
        else{

          setRequestType('create');

        }

        setPageStatus("success");
      
      }

      //Set status to 'error-invalid'
      else if (response.status === "error-invalid") {

        setPageStatus("error-invalid");
        setCustomMessage("Oops you don't have access to reset MFA on your own");

      }

      //Set status to 'error-fatal'
      else if (response.status === "error-fatal") {

        setPageStatus("error-fatal");

      }

      //Set status to 'error-timeout'
      else {

        setPageStatus("error-timeout");

      }
    };


    //Used to call the API & save the state
    const mfaResetAPI = async () => {

      //API call to react backend
      var requestBody = {
      };
  
      var response = await ReactBackend("mfaReset", requestBody);
  
      //set response message var
      var responseMessage = String(response.responseBody.message)
  
      if (response.status === "success") {
        setPageStatus("success");
        setRequestType("modify")
      }
  
      //Set status to 'error-invalid'
      else if (response.status === "error-invalid") {
        setPageStatus("error-invalid");
  
        if (responseMessage.length > 0) {
          setCustomMessage(responseMessage);
        }
  
      }
  
      //Set status to 'error-fatal'
      else if (response.status === "error-fatal") {
        setPageStatus("error-fatal");
        
        if (responseMessage.length > 0) {
          setCustomMessage(responseMessage);
        }
      }
  
      //Set status to 'error-timeout'
      else if (response.status === "error-timeout") {
        setPageStatus("error-timeout");
      }
    };
      

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Onload Render
    useEffect(() => {

      //Get the users profile
      if (pageStatus === "onload") {

        CallAPI();
        
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageStatus]);


    //Pending Render
    useEffect(() => {

      //Reset User MFA
      if (pageStatus === "pending") {
        
        mfaResetAPI()
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageStatus]);




  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
      <PageResponse
        setPageStatus={setPageStatus}
        pageStatus={pageStatus}
        requestType={requestType}
        customMessage={customMessage}

        // Are you sure screen
        pageView={
          <div className="Feedback-Body-Container">

              {/* Text */}
              <div className="Feedback-Centre">
                 <h1 >Are you sure you want to reset your MFA?</h1> 
                 <div style={{display: 'flex', gap: '1.5rem'}}>

                    {/* Buttons */}
                    <Button
                    text="Yes"
                    className="Button-Primary"
                    clickHandler={() => setPageStatus("pending")}
                    />
                    <Button
                    text="No"
                    className="Button-Secondary"
                    clickHandler={() => setRequestType("cancel")}
                    />
                  </div>
              </div> 
          </div>
        }

        // Change your mind //
        pageCancel={
          <div className="Feedback-Body-Container Feedback-Grid">
            
              {/* Image */}
              <div className="Grid-Left">
                <img className="Grid-Image"  alt="Programmer" src={ProgrammerGraphic} />
              </div> 

              {/* Text */}
              <div className="Grid-Right">
                <h1>Changed your mind?</h1>
                <p>No worries! Enjoy the rest of your day!</p>
              </div>

          </div>  
        }

        // MFA Reset //
        pageModify={
          <div className="Feedback-Body-Container Feedback-Grid">
                
              {/* Text*/}
              <div className="Grid-Left">
                <h1>Your MFA has been reset!</h1>
                <p>Next time you log in, you will be prompted to set up your MFA. Please follow the steps displayed and use the Authenticator app on your phone when prompted. Once complete, you will be able to successfully log in.</p>
              </div>

              {/* Image */}
              <div className="Grid-Right">
                <img className="Grid-Image" src={MFAAuth} alt="Authentication Graphic"></img>
              </div>
      
          </div>
        }
        
        // If the user does not have a cloud passport account //
          pageCreate={
            <div className="Feedback-Body-Container Feedback-Grid">

              {/* Text */}
              <div className="Grid-Left">
                  <h1>Oops, It looks like you are not a Cloud Passport user!</h1>
                  <p>Click the button below to sign up for a Cloud passport account.</p>
                  
                  {/* Button */}
                    <Button
                    className="Button-Primary"
                    text="Sign Up"
                    clickHandler={() => window.location.href =`${process.env.REACT_APP_CS_CUSTOMERPORTAL_URL}`}
                    >
                    </Button>
              </div>

              {/* Image */}
              <div className="Grid-Right">
                <img className="Grid-Image" alt="Access-Denied" src={Unauthenticated} />
              </div> 

            </div>
          }
      >
      </PageResponse>
    )
  //------------------------------------------------------
}

export default MFAReset;


