//Libraries
import React from 'react';

//Images
import GoogleLogo from '../Images/Google_Logo.svg'
import Logo from '../Images/Icon_LendleaseCloudLogo.svg'
import llLogo from '../Images/LL_Logo.png'

// CSS
import './GenericComponents.css'


//------------------------------------------------------
//  Collection of Simple Components Used in the App
//------------------------------------------------------

// Button Component -> Button Primary or Button Secondary // 
export const Button = ({ text, className, clickHandler }) => {
    return (
        <button className={className} onClick={clickHandler}> {text} </button>
    )
}


// Header Component //
export const Header = () => {
    return (
        <header>
            <img alt='Lendlease Cloud Lockup' src={Logo} style={{width: '200px'}}/> 
        </header>  
    )
}


// Footer Component //
export const Footer = () => {
    return (
        <footer>
            <img alt='Lendlease Logo' src={llLogo} style={{width: '120px',  paddingRight: '1rem'}}/>
            <p>For support, please contact <a href="mailto:ServiceDesk@lendlease.com">ServiceDesk@lendlease.com</a></p>
        </footer>
    )
}


// Hero Component -> used on Onboarding Page //
export const Hero = ({subHeader, description, controls }) => {
    return(
        <div className='Hero'>
            <div className="Info-Container">
                <h2 style={{fontWeight: '600'}}>Lendlease Cloud Portal</h2>
                <h4 style={{fontWeight: '500'}}>{subHeader}</h4>
                <p style={{fontFamily: 'var(--light)'}}>{description}</p>
                {controls}
            </div>
            <div className="Icon-Container">
                <img className="Google-Icon" alt='Google Logo' src={GoogleLogo} />
            </div>
        </div>
    )
}


// Information Component -> Used on Onboarding Page, Why Google Cloud //
export const Information = () => {
    return(
        <div className='Information-Section'>
            <h4 style={{color: '#626262', fontWeight: "600"}}>Why Google Cloud?</h4>
            <p style={{color: '#4D4D4D', lineHeight: '1.7'}}>Digital transformation is more than “lifting and shifting” old IT infrastructure to the cloud for cost saving and convenience. True transformation is the empowerment of people to streamline business processes through automation, while removing complexity. Contact us on the cloud guild today and learn how other Lendlease teams have innovated with Google Cloud. </p>
        </div>
    )
}


// Input Component //
export const InputField = ({ name, placeholder, onChange, errorVisible }) => {
    return(
        <input className={errorVisible? 'Input Input-Invalid' : 'Input'} type="text" placeholder={placeholder} name={name} onChange={onChange}/>
    )
}


// Error Label Component //
export const ErrorLabel = ({ visible, message }) => {
    return (
        <label className={visible === true ? "Error-Label" : "Hide"}> {message} </label>
    )
}
