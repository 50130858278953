// Libraries
import React from "react";

// CSS
import './Tabs.css'


//------------------------------------------------------
//  Tab Item -> Navigation Component
//------------------------------------------------------

export function TabItem({ id, title, activeTab, setActiveTab }) {
 
  // Set the Tab Link to active -> display the matching content //
  const handleClick = () => setActiveTab(id);
  
    return (
      <>
        {/* // Navigation Link // */}
        <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
          { title }
        </li>
      </>
    );
};


//------------------------------------------------------
//  Tab Content -> Content for specified Tab Item
//------------------------------------------------------

export function TabContent({ id, activeTab, children }) {
    return (
   
      // return tab content if link currenlty active -> matches id //
      activeTab === id ? <> { children } </> : null
   
    );
};
