//Libraries
import React from "react";
import {Routes, Route} from "react-router-dom";

//Pages
import Onboarding from "./Onboarding/onboarding";
import MFAReset from './MFAReset/mfareset'
import WriteUsageMetric from "./Library/WriteUsageMetric";
import NotFound from "./Not Found/NotFound";

// Components 
import { Header, Footer } from "./Components/Generic/GenericComponents";

//Styles
import './App.css'


function App() {

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
      <>
        {/* Header */}
        <Header />
    
        <div className="Onboarding-Body">

        {/* Background Components that DO NOT return any HTML */}
          <WriteUsageMetric></WriteUsageMetric>

          <Routes>
            {/* Main Pages */}
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Onboarding />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/home" element={<Onboarding />} />
            <Route path="/mfareset" element={<MFAReset />} />
          </Routes>

        </div>
         
        {/* Footer */}
        <Footer />
        
      </>
    );

  //------------------------------------------------------
}

export default App;
